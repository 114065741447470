import { ENV } from './system';

export const GrowthDiscoverLinks = isShowTopCourse => {
  const defaultLinks = [
    {
      name: 'Explore Growth Paths',
      link:
        'https://docs.google.com/presentation/d/1ia5GdebBrFZoSvXqM5hk9kUXYGaSYFUq46m8Uz8I7E4/edit#slide=id.g75358c0721_0_16615'
    },
    {
      name: 'Read Growth Stories',
      link:
        'https://docs.google.com/presentation/d/1b_DCilNI08I8okgFYg_8FmOG4oL7hHVtab54aAOKFpY/edit#slide=id.g75358c0721_0_1661'
    }
  ];
  const topCourseLink = [
    {
      name: 'Top Courses',
      link: ''
    }
  ];
  return isShowTopCourse ? topCourseLink.concat(defaultLinks) : defaultLinks;
};

export const JigsawLink = (mail, env) => {
  return env === ENV.PRODUCTION
    ? `https://jigsaw.thoughtworks.net/consultants/${mail}`
    : `https://staging.jigsaw.thoughtworks.net/consultants/${mail}`;
};

export const coreCapabilityFeedbackLink = 'https://forms.gle/D66qQLE9fSPwkvdB9';

export const FAQsLink =
  'https://docs.google.com/document/d/1mfw_yu3kPm75Htvc-I9tdhj1sb7qVz007svwp1I5jWg/edit?usp=sharing';

export const DisclaimerLink =
  'https://docs.google.com/document/d/1aFqN5Fe5yFoU3bgYtUkpcddLs0Oo2enV-96VgaLJUXE';

export const PathwaysIntroductionLink =
  'https://campus.thoughtworks.net/learn/course/43852/how-to-use-pathways-for-your-growth?generated_by=21582&hash=3eb9dacbf003d796e4eed9e6c4120f038c3d2d49';

export const CapabilityModuleLink =
  'https://docs.google.com/presentation/d/1JCEcE9OmQ1ocdxKf37gzNtWmC63xaBMEs-uFUQ_8LJA/edit#slide=id.g3fa2669660_5_1636';

export const CompetencyAndArchetypeTipModalLink =
  'https://docs.google.com/presentation/d/1JCEcE9OmQ1ocdxKf37gzNtWmC63xaBMEs-uFUQ_8LJA/edit#slide=id.g3fa2669660_5_1636';

export const PATHWAYS_LINK = {
  QA: 'qa.pathways.thoughtworks.net',
  STAGING: 'staging.pathways.thoughtworks.net',
  PRODUCTION: 'pathways.thoughtworks.net'
};
export const SummitCyclePath = '/performance/cycles';
export const GoogleChatLink = 'https://chat.google.com/u/1/room/';
