import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button
} from '@mui/material';
import ArrowDown from '../../../components/Common/SvgIcons/ArrowDown';
import LampIcon from '../../../assets/Icons/Lamp.png';
import { useUserContext } from '../../../contexts/UserContext';
import { fetchCycleBasedInfo } from '../../../api/domain/connectWithSummit';
import { PathwaysTrackEvents } from '../../../support/pathwaysTrack';
import {
  matomoEventListByDifferentCycleCondition,
  summitCycleCondition
} from '../../../constants/summitCycleCondition';
import globalStyles from '../../../globalStyles';
import { connectSummitContent, dashboardDes } from '../language';
import { SummitCyclePath } from '../../../constants/links';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';

const AccordionStyle = styled(Accordion)({
  margin: 0,
  padding: '0 20px 0 16px'
});

const AccordionSummaryStyle = styled(AccordionSummary)({
  padding: '16px 0 12px 0',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0
  },
  '&.Mui-expanded': {
    minHeight: 'auto'
  },
  '&.MuiAccordionSummary-content': {
    margin: 0
  }
});

const AccordionDetailsStyle = styled(AccordionDetails)({
  padding: '0 22px 0 40px'
});

const TypographyFoldStyle = styled(Typography)({
  fontSize: '16px',
  color: globalStyles.black3,
  lineHeight: '32px',
  paddingLeft: '8px'
});

const OpenSummitButton = styled(Button)({
  padding: '8px 16px',
  background: globalStyles.red1,
  boxShadow: '0px 2px 4px rgba(95, 95, 95, 0.5)',
  borderRadius: '7px',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  color: globalStyles.grey81,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: globalStyles.hoverRed
  }
});
const ConnectSummit = () => {
  const { state } = useUserContext();
  const userFirstname = state?.user?.firstName ?? '';
  const userLastname = state?.user?.lastName ?? '';
  const userName = `${userFirstname} ${userLastname}`;
  const { pathwaysToSummit, fileInfo } = dashboardDes.connectSummit;

  const [expanded, setExpanded] = useState(true);
  const [curSummitContent, setCurSummitContent] = useState({});

  const localStorageConnectSummitInfo =
    localStorage.getItem('connectSummitInfo') &&
    JSON.parse(localStorage.getItem('connectSummitInfo'));
  const cycleType = cycleDetail => {
    if (isEmpty(cycleDetail)) {
      return summitCycleCondition.noCyclesRecord;
    }

    const onGoingCycles = cycleDetail.filter(
      item => item.status === 'OPEN' || item.status === 'DRAFT'
    );

    if (isEmpty(onGoingCycles)) {
      const doneCycles = cycleDetail.filter(
        item => item.status === 'DONE' || item.status === 'UNREAD'
      );
      if (isEmpty(doneCycles)) {
        return summitCycleCondition.noCyclesRecord;
      }
      return summitCycleCondition.doneCycle;
    }

    const haveCheckInNotes = onGoingCycles.some(
      item => item.checkNotes && item.checkNotes.length > 0
    );
    if (haveCheckInNotes) {
      return summitCycleCondition.ongoingCycleWithNotes;
    }
    return summitCycleCondition.ongoingCycleWithoutNotes;
  };
  const compareCycleType = ({ curCycleType }) => {
    if (isEmpty(localStorage.getItem('connectSummitInfo'))) {
      return true;
    }
    const { cycleType = '', status = true } = JSON.parse(
      localStorage.getItem('connectSummitInfo')
    );
    if (curCycleType !== cycleType) {
      return true;
    }
    return status;
  };
  const handleChange = () => (event, isExpanded) => {
    setExpanded(isExpanded);
    localStorage.setItem(
      'connectSummitInfo',
      JSON.stringify({
        ...localStorageConnectSummitInfo,
        status: isExpanded
      })
    );
  };
  const jumpToSummit = () => {
    const connectSummitInfo = JSON.parse(
      localStorage.getItem('connectSummitInfo')
    );
    const { cycleType } = connectSummitInfo;
    const location = window.location;
    const { host, protocol } = location;
    let summitHost = pathwaysToSummit['qa'].summit;
    Object.keys(pathwaysToSummit).forEach(key => {
      const { pathways, summit } = pathwaysToSummit[key];
      if (host === pathways) {
        summitHost = summit;
      }
    });
    window.open(protocol + '//' + summitHost + SummitCyclePath, '_blank');

    switch (cycleType) {
      case summitCycleCondition.noCyclesRecord:
        PathwaysTrackEvents(
          matomoEventListByDifferentCycleCondition.noCyclesRecord
        );
        break;
      case summitCycleCondition.ongoingCycleWithoutNotes:
        PathwaysTrackEvents(
          matomoEventListByDifferentCycleCondition.ongoingCycleWithoutNotes
        );
        break;
      case summitCycleCondition.ongoingCycleWithNotes:
        PathwaysTrackEvents(
          matomoEventListByDifferentCycleCondition.ongoingCycleWithNotes
        );
        break;
      case summitCycleCondition.doneCycle:
        PathwaysTrackEvents(matomoEventListByDifferentCycleCondition.doneCycle);
        break;
      default:
        break;
    }
  };
  const getDefaultConnectSummit = res => {
    const cycleKey = cycleType(res);
    const status = compareCycleType({ curCycleType: cycleKey });

    setExpanded(status);

    setCurSummitContent(connectSummitContent(userName)[cycleKey]);

    localStorage.setItem(
      'connectSummitInfo',
      JSON.stringify({
        ...localStorageConnectSummitInfo,
        cycleType: cycleKey,
        status: status
      })
    );
  };

  useEffect(() => {
    fetchCycleBasedInfo().then(res => {
      getDefaultConnectSummit(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.connectSummit}>
      <AccordionStyle expanded={expanded} onChange={handleChange()}>
        <AccordionSummaryStyle
          expandIcon={
            <div className={styles.circleArrow}>
              <ArrowDown />
            </div>
          }
          aria-controls="panel1bh-content"
          id="panelHeader"
        >
          <div className={styles.titleInfo}>
            <div className={styles.lampIcon}>
              <img src={LampIcon} alt="" />
            </div>
            <div className={styles.title}>{fileInfo.title}</div>
          </div>

          {!expanded && (
            <TypographyFoldStyle>{curSummitContent?.fold}</TypographyFoldStyle>
          )}
        </AccordionSummaryStyle>
        <AccordionDetailsStyle>
          <div className={styles.detailList}>
            {curSummitContent &&
              curSummitContent.unFold?.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
          </div>
        </AccordionDetailsStyle>
        <div className={styles.summitButtonWrapper}>
          <OpenSummitButton onClick={jumpToSummit}>
            {fileInfo.button}
          </OpenSummitButton>
        </div>
      </AccordionStyle>
    </div>
  );
};

export default ConnectSummit;
